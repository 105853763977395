<template>
  <q-card class="text-white font-semibold" :class="bgClass">
    <q-card-section>
      <div class="cardValue" v-if="value">{{ value }}</div>
      <div class="cardTitle" v-if="title">{{ title }}</div>
      <div class="cardSubTitle" v-if="subTitle">{{ subTitle }}</div>
    </q-card-section>
    <slot />
    <Loader :loading="loading" inner />
  </q-card>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  components: { Loader },
  props: {
    variant: {
      type: String,
      default: '',
      validator: val => {
        if (val) {
          return [
            'default',
            'primary',
            'success',
            'warning',
            'info',
            'danger'
          ].includes(val)
        }
        return true
      }
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const bgClass = {
      'text-black': !props.variant,
      'bg-gray text-black': props.variant === 'default',
      'bg-primary': props.variant === 'primary',
      'bg-success': props.variant === 'success',
      'bg-warning': props.variant === 'warning',
      'bg-info': props.variant === 'info',
      'bg-danger': props.variant === 'danger'
    }

    return { bgClass }
  }
}
</script>

<style lang="scss" scoped>
.cardValue {
  @apply text-2xl;
}

.cardTitle {
  @apply text-lg;
}

.cardSubTitle {
  @apply text-sm;
}
</style>
