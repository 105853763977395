<template>
  <q-card class="text-white px-2" :class="bgClass">
    <q-card-section>
      <div class="cardHeader" v-if="header">{{ header }}</div>
      <Loader :loading="loading" v-if="loading" inner />
      <div v-else-if="!loading && noData">
        <div class="q-pa-xl column items-center justify-center text-primary">
          <q-icon name="description" size="xl" />
          <span class="text-h7">No Data</span>
        </div>
      </div>
      <div v-else>
        <div class="cardValue text-center" v-if="value">{{ value }}</div>
        <div class="cardTitle text-center" v-if="title">{{ title }}</div>
        <slot />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  props: {
    variant: {
      type: String,
      default: '',
      validator: val => {
        if (val) {
          return [
            'default',
            'primary',
            'success',
            'warning',
            'info',
            'danger'
          ].includes(val)
        }
        return true
      }
    },
    header: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    noData: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: { Loader },
  setup(props) {
    const bgClass = {
      'text-black': !props.variant,
      'bg-gray text-black': props.variant === 'default',
      'bg-primary': props.variant === 'primary',
      'bg-success': props.variant === 'success',
      'bg-warning': props.variant === 'warning',
      'bg-info': props.variant === 'info',
      'bg-danger': props.variant === 'danger'
    }

    return { bgClass }
  }
}
</script>

<style lang="scss" scoped>
.cardHeader {
  @apply font-semibold;
}

.cardValue {
  @apply text-2xl font-semibold;
}

.cardTitle {
  @apply text-xs font-semibold;
  @apply uppercase;
}
</style>
