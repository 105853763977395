<template>
  <inventory-SKU-movement />
</template>

<script>
import InventorySKUMovement from '@/components/pages/inventory/InventorySKUMovement'

export default {
  components: {
    InventorySKUMovement
  }
}
</script>
